<template>
  <div
    class="modal-producto modal fade"
    id="myModal"
    tabindex="10"
    role="dialog"
    v-if="producto"
  >
    <div class="modal-dialog modal-fullscreen-sm-down">
      <div class="modal-content">
        <div class="modal-body">
          <div class="detalle">
            <!-- comentarios componente -->
            <div class="alert alertas" role="alert">
              <i class="bi bi-check"></i>
              <span class="mensaje-error">
                El producto fue agregado correctamente
              </span>
            </div>

            <!-- Imagen producto -->
            <div class="img-descripcion">
              <ImagenProducto :producto="producto" />
              <Social :producto="producto" />
            </div>

            <!-- Cerrar modal -->
            <div ref="Close" class="cerrar" data-bs-dismiss="modal">
              <svg
                height="18"
                viewBox="0 0 329.26933 329"
                width="18"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"
                ></path>
              </svg>
            </div>
          </div>

          <!-- Contenedo de los productos -->
          <div class="contenedor-producto">
            <div class="info d-flex justify-content-between mb-3">
              <div class="info-descripcion">
                <h1 class="fw-bold">
                  {{ producto.nombre }}
                </h1>

                <h2 v-html="producto.descripcion" class="sp contenido">
                  
                </h2>
              </div>
              <div class="info-precio">
                <div v-if="producto.precio>0" class="precio oferta">
                  <p>{{ precio(producto,0) }}{{ restaurante.moneda }}</p>
                </div>
                <div v-else v-if="textonulo != null && textonulo != '' " class="precio oferta">
                  {{ textonulo }}
                </div>
                
              </div>
            </div>

            <!-- Comentarios y tabs -->
            <div class="tabs-opciones">
              <ul
                class="nav nav-pills mb-3 opciones-pill"
                id="pills-tab"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    :href="`#opciones-extras-${producto.id}`"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                    @click="pestanaComentarios(false)"
                  >
                    {{traducciones.find((item)=>item.item == 'Opciones').value}}
                  </a>
                </li>
                <li class="nav-item">
                  <a
                   v-if="opiniones"
                    class="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    :href="`#opciones-comentarios-${producto.id}`"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                    @click="pestanaComentarios(true)"
                  >
                    {{traducciones.find((item)=>item.item == 'Comentarios').value}}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <!-- Tabs para Comentarios y productos -->
          <div class="tab-content">
            <!-- Opciones del producto -->
            <div
              :id="`opciones-extras-${producto.id}`"
              class="extras tab-pane fade show active"
            >
              <!-- Alergenos -->
              <Alergeno
                v-if="producto.alergenos.length"
                :alergenos="producto.alergenos"
              />

              <!-- Tamaño Platos -->
              <Tamanos v-show="muestraTamanos==0" :productos="producto" />

              <!-- Opciones -->
              <Opciones v-show="muestraIngredientes"/>

              <!-- Suplementos -->
              <Suplementos
                v-if="producto.suplementos.length"
                :suplementos="producto.suplementos"
              />
            </div>

            <!-- Comentarios contenedor -->
            <div
              v-if="opiniones"
              :id="`opciones-comentarios-${producto.id}`"
              class="comentarios tab-pane fade"
            >
              <Comentarios :producto="producto" />
            </div>
          </div>

          <!-- Boton de compra -->
          <div class="comprar" v-if="!comentarios && muestraCesta">
            <button
              type="button"
              class="btn btn-verde w-100 producto-total"
              data-bs-dismiss="modal"
              @click="agregarCarrito(producto)"
            >
              <span>
                <span>
                  <img src="@/assets/images/carrito-compra-boton.svg" alt="" />
                </span>
                <span class="fw-bold"> {{traducciones.find((item)=>item.item == 'Pedir').value}} 1 </span>
                {{traducciones.find((item)=>item.item == 'por').value}}
                <span class="fw-bold"> {{ precio(producto,1) }}{{ restaurante.moneda }}</span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import {
  computed,
  onBeforeMount,
  onMounted,
  ref,
  watch,
  inject,
} from "@vue/runtime-core";
import * as bootstrap from "bootstrap";

import Alergeno from "@/components/producto-modal/Alergeno";
import Comentarios from "@/components/producto-modal/Comentarios";
import Opciones from "@/components/producto-modal/Opciones";
import Suplementos from "@/components/producto-modal/Suplementos";
import Social from "@/components/producto-modal/Social";
import ImagenProducto from "@/components/producto-modal/ImagenProducto";
import Tamanos from "@/components/producto-modal/Tamanos";

export default {
  methods: {
 
   precio: function (value,estotal) {
   var suplemento = 0;
       if (value.suplementoextra !== undefined &&
            value.suplementoextra !== null &&
            (
                // Verificar si es un número decimal distinto de 0
                (typeof value.suplementoextra === 'number' && value.suplementoextra > 0) ||
                (typeof value.suplementoextra === 'string' && parseFloat(value.suplementoextra.replace(/,/g, '.')) > 0) ||
                // Verificar si es un porcentaje válido distinto de 0
                (
                    typeof value.suplementoextra === 'string' &&
                    /^(\d+|(\.\d+))(\.\d+)?%$/.test(value.suplementoextra) &&
                    parseFloat(value.suplementoextra.replace('%', '').replace(/,/g, '.')) > 0
                )
            ) &&
            this.restaurante.suplemento == 0)
                suplemento = value.suplementoextra.toString().replace(/,/g, '.');
            else
                suplemento = this.restaurante.suplemento;
            
   if (estotal)
        var price = value.total;
   else
        var price = value.precio;

   if (/^(\d+|(\.\d+))(\.\d+)?%$/.test(suplemento)) {
        suplemento = suplemento.toString().replace(/,/g, '.');
        suplemento = suplemento.substring(0,suplemento.length-1);
        suplemento = Number(price)*Number(suplemento)/100;
        }
   price= Number(price) + Number(suplemento);
   
  if (this.restaurante.general[0].redondeo)
     {
     price = Math.round(price*10)/10;
     }   
    if (this.restaurante.moneda !=='XAF' && this.restaurante.moneda !=='')
      return Number(price).toFixed(2)
    else
        return Number(price).toFixed(0)
    
    }
  },
  name: "Modal",
  components: {
    Alergeno,
    Comentarios,
    Opciones,
    Suplementos,
    Social,
    ImagenProducto,
    Tamanos,
  },

  setup() {
    const route = useRoute();
    const store = useStore();
    const router = useRouter();
    const id = route.params.product_id;
    const dialog = inject("$swal");
    if (store.state.restaurante.restaurante.modulos.find((element) => element.nombre === 'Pedido') !== undefined
        || store.state.restaurante.restaurante.modulos.find((element) => element.nombre === 'AutoPedidos') !== undefined || store.state.restaurante.restaurante.modulos.find((element) => element.nombre === 'Comandero') !== undefined
        )
        var muestraCesta = true;
    var muestraTamanos = false;
    var muestraIngredientes = false;

    const comentarios = ref(false);

    onBeforeMount(() => {
      store.dispatch("productos/obtenerProducto", id);
    });

    onMounted(() => {
        
      let myModal = new bootstrap.Modal(document.getElementById("myModal"), {
        backdrop: "true",
      });
      store.dispatch("productos/fetchComentarios", id);
      myModal.show();

      document
        .getElementById("myModal")
        .addEventListener("hidden.bs.modal", function (event) {
       
          backdrop();
        });
    });

    const backdrop = () => {
     
      router.go(-1);
      store.commit("productos/vaciarProducto", producto);
    };

    const agregarCarrito = (producto) => {
      store.dispatch("carrito/agregarCarrito", producto);
      dialog({
        position: "bottom",
        toast: true,
        showConfirmButton: false,
        icon: "success",
        title: "El producto ha sido agregado a la cesta de compras",
        timer: 2000,
      });
    };

    const producto = computed(() => store.state.productos.producto);
    const precioExtra = computed(
      () => store.getters["productos/extrasProducto"]
    );

    const pestanaComentarios = (e) => {
      comentarios.value = e;
    };

    watch(
      () => route.params.product_id,
      (value, prevValue) => {
       // console.log(value, prevValue);

        store.dispatch("productos/obtenerProducto", value);
      }
    );

    const restaurante = computed(() => store.state.restaurante.restaurante);
    const textonulo = computed(() => store.state.restaurante.restaurante.general[0].textonulo);
     const opiniones = computed(() => store.state.restaurante.restaurante.general[0].opiniones);
    
    const traducciones = store.state.restaurante.restaurante.traducciones.filter( item => item.locale === store.state.idiomas.options.locale);
    return {
      producto,
      muestraTamanos,
      muestraIngredientes,
      muestraCesta,
      agregarCarrito,
      precioExtra,
      comentarios,
      pestanaComentarios,
      restaurante,
      opiniones,
      textonulo,
      traducciones
    };
  },
};
const $ = require('jquery');
window.$ = $;
$(function() {
    window.addEventListener('popstate', function(event) {
    $('.modal-backdrop').hide();
     $('body').removeClass('modal-open');
    });    
});
</script>

<style lang="scss" scoped>
.img-descripcion {
  position: relative;
}

.modal-body {
  padding: 0;
}

.tab-content {
  padding: 25px 25px 0;

  @media (max-width: 10px) {
  }
}

.info {
  // align-items: center;
  padding: 25px 25px;
  margin: 0;
  background: rgba(243, 244, 244, 0.14);

  .info-descripcion {
    width: 75%;
  }

  .info-precio {
    text-align: right;

    .btn {
      font-size: 13px;
      padding: 5px 10px;
    }
  }

  .precio {
    font-size: 22px;
    font-weight: bold;
    /* color: #0aa931; */

    @media (max-width: 698px) {
      font-size: 16px;
    }
  }

  h1 {
    font-size: 22px;
    text-align: left;
    margin-bottom: 8px;

    @media (max-width: 698px) {
      font-size: 16px;
    }
  }

  h2 {
    font-size: 14px;
    margin: 0;
    color: #a4a4a4;
    margin-bottom: 5px;
    font-weight: 400;

    @media (max-width: 698px) {
      font-size: 12px;
    }
  }
}

.cerrar {
  width: 42px;
  height: 42px;
  position: absolute;
  top: 25px;
  right: 25px;
  background: #fff;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  cursor: pointer;

  svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    fill: #283646;
  }

  &:hover {
    text-decoration: none;
  }

  i {
    font-size: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
  }
}

/* Animation */
@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

/* :not(:checked) is a filter, so that browsers that don’t support :checked don’t 
      follow these rules. Every browser that supports :checked also supports :not(), so
      it doesn’t make the test unnecessarily selective */
.rating:not(:checked) > input {
  position: absolute;
  // left: -9999px;
  visibility: hidden;
  clip: rect(0, 0, 0, 0);
}

.rating:not(:checked) > label {
  float: right;
  width: 1em;
  /* padding:0 .1em; */
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 26px;
  /* line-height:1.2; */
  color: #ddd;
}

.rating:not(:checked) > label:before {
  content: "★ ";
}

.rating > input:checked ~ label {
  color: #ffc107;
}

.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  color: #ffc107;
}

.rating > input:checked + label:hover,
.rating > input:checked + label:hover ~ label,
.rating > input:checked ~ label:hover,
.rating > input:checked ~ label:hover ~ label,
.rating > label:hover ~ input:checked ~ label {
  color: #ffc107;
}

.rating > label:active {
  position: relative;
  top: 2px;
  left: 2px;
}

.rating-mensaje {
  display: none;
  font-weight: bold;
  color: green;
  text-align: center;
  font-size: 13px;
}

.alertas {
  display: none;
}

.no-imagen {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  object-fit: fill;
  height: 125px;
  width: 125px;

  @media (max-width: 968px) {
    width: 80px;
    height: 80px;
  }
}

.agregar-foto {
  position: absolute;
  z-index: 1;
  width: 100%;
  background: #46b975;
  text-align: left;
  padding: 15px;
  font-weight: bold;
  bottom: 0;
  color: #fff;
  font-size: 12px;
}

#agregar-fotografia {
  margin-bottom: 25px;
  padding: 10px 0;

  button {
    width: 100%;
    padding: 10px 25px;
    font-weight: bold;
  }
}

.no-fotografia {
  position: relative;
}

.tabs-opciones {
  margin-bottom: 25px;
}

.comentario-form {
  margin-bottom: 25px;

  .btn {
    width: 100%;
    margin-top: 16px;
    padding: 10px 0;
  }

  p {
    font-weight: bold;
    text-align: center;
  }
}

.opciones-pill {
  // border-bottom: 1px solid #ccc;
  box-shadow: 0px 14.8222px 37.0556px rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  border-radius: 12px;
  margin: 30px auto;
  width: max-content;

  #pills-home-tab {
    &.active {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }
  }

  #pills-profile-tab {
    &.active {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }

  a {
    color: #3c505a;
    cursor: pointer;
    padding: 15px 40px;
    opacity: 0.5;
    &.active {
      background: #f3f6f9;
      box-shadow: 0px 14.8222px 37.0556px rgba(0, 0, 0, 0.1);
      color: #3c505a;
      border: 0;
      font-weight: bold;
      opacity: 1;
    }
  }
}

.producto-total {
  padding: 20px 0;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.15) 0px -11px 40px;
}
</style>
